@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//container
.container {
  padding: 3% !important;
}

.containerItem {
  padding-bottom: 3%;
}

//text
.textHeaderCard {
  padding-bottom: 3%;
}

//input text field
.textField {
  margin: 10px auto !important;
}

//button
.button {
  text-transform: none !important;
  // padding: 2% 0 !important;
}

.progress {
  position: absolute;
}
